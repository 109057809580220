import React from "react"

const UNosaci = () => {
  return (
    <div className="box" style={{ width: "100%" }}>
      <div className="column">
        <p>
          Proizvodna dužina: 6m, 12m. Po zahtevu moguće je isporučiti veće
          dimenzije i sečenje po meri.
        </p>
      </div>
      <div className="table-scroll">
        <table class="table is-bordered is-hoverable">
          <tbody>
            <tr className="has-background-link has-text-white">
              <td rowSpan={2}>
                <br />
                Oznaka UPN
              </td>
              <td colSpan={6}>Mere (mm)</td>
              <td rowSpan={2}>
                <br />
                Masa (kg)
              </td>
            </tr>
            <tr>
              <td>h</td>
              <td>b</td>
              <td>s</td>
              <td>t</td>
              <td>r</td>
              <td>r1</td>
            </tr>
            <tr>
              <td>65</td>
              <td>65</td>
              <td>42</td>
              <td>5,5</td>
              <td>7,5</td>
              <td>7,5</td>
              <td>4,0</td>
              <td>7,09</td>
            </tr>
            <tr>
              <td>80</td>
              <td>80</td>
              <td>45</td>
              <td>6,0</td>
              <td>8,0</td>
              <td>8,0</td>
              <td>4,0</td>
              <td>8,90</td>
            </tr>
            <tr>
              <td>100</td>
              <td>100</td>
              <td>50</td>
              <td>6,0</td>
              <td>8,5</td>
              <td>8,5</td>
              <td>4,5</td>
              <td>10,90</td>
            </tr>
            <tr>
              <td>120</td>
              <td>120</td>
              <td>55</td>
              <td>7,0</td>
              <td>9,0</td>
              <td>9,0</td>
              <td>4,5</td>
              <td>13,70</td>
            </tr>
            <tr>
              <td>140</td>
              <td>140</td>
              <td>60</td>
              <td>7,0</td>
              <td>10,0</td>
              <td>10,0</td>
              <td>5,0</td>
              <td>16,40</td>
            </tr>
            <tr>
              <td>160</td>
              <td>160</td>
              <td>65</td>
              <td>7,5</td>
              <td>10,5</td>
              <td>10,5</td>
              <td>5,5</td>
              <td>19,30</td>
            </tr>
            <tr>
              <td>180</td>
              <td>180</td>
              <td>70</td>
              <td>8,0</td>
              <td>11,0</td>
              <td>11,0</td>
              <td>5,5</td>
              <td>22,50</td>
            </tr>
            <tr>
              <td>200</td>
              <td>200</td>
              <td>75</td>
              <td>8,5</td>
              <td>11,5</td>
              <td>11,5</td>
              <td>6,0</td>
              <td>26,00</td>
            </tr>
            <tr>
              <td>220</td>
              <td>220</td>
              <td>80</td>
              <td>9,0</td>
              <td>12,5</td>
              <td>12,5</td>
              <td>6,5</td>
              <td>30,00</td>
            </tr>
            <tr>
              <td>240</td>
              <td>240</td>
              <td>85</td>
              <td>9,5</td>
              <td>13,0</td>
              <td>13,0</td>
              <td>6,5</td>
              <td>34,00</td>
            </tr>
            <tr>
              <td>260</td>
              <td>260</td>
              <td>90</td>
              <td>10,0</td>
              <td>14,0</td>
              <td>14,0</td>
              <td>7,0</td>
              <td>39,50</td>
            </tr>
            <tr>
              <td>280</td>
              <td>280</td>
              <td>95</td>
              <td>10,0</td>
              <td>15,0</td>
              <td>15,0</td>
              <td>7,5</td>
              <td>43,00</td>
            </tr>
            <tr>
              <td>300</td>
              <td>300</td>
              <td>100</td>
              <td>10,0</td>
              <td>16,0</td>
              <td>16,0</td>
              <td>8,0</td>
              <td>48,00</td>
            </tr>
            <tr>
              <td>320</td>
              <td>320</td>
              <td>100</td>
              <td>14,0</td>
              <td>17,5</td>
              <td>17,5</td>
              <td>8,75</td>
              <td>61,00</td>
            </tr>
            <tr>
              <td>350</td>
              <td>350</td>
              <td>100</td>
              <td>14,0</td>
              <td>16,0</td>
              <td>16,0</td>
              <td>8,0</td>
              <td>62,00</td>
            </tr>
            <tr>
              <td>380</td>
              <td>380</td>
              <td>102</td>
              <td>13,5</td>
              <td>16,0</td>
              <td>16,0</td>
              <td>8,0</td>
              <td>65,00</td>
            </tr>
            <tr>
              <td>400</td>
              <td>400</td>
              <td>110</td>
              <td>14,0</td>
              <td>18,0</td>
              <td>18,0</td>
              <td>9,0</td>
              <td>74,00</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default UNosaci
